import { render, staticRenderFns } from "./Expediente.vue?vue&type=template&id=f52dcf3c&scoped=true"
import script from "./Expediente.vue?vue&type=script&lang=js"
export * from "./Expediente.vue?vue&type=script&lang=js"
import style0 from "./Expediente.vue?vue&type=style&index=0&id=f52dcf3c&prod&scoped=true&lang=css"
import style1 from "./Expediente.vue?vue&type=style&index=1&id=f52dcf3c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f52dcf3c",
  null
  
)

export default component.exports